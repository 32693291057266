<template>
    <div class="n-header n-bg-1 n-col-1"
         id="header">
        <div class="n-header-inner n-h-1 n-flex n-flex-a-c n-flex-j-sb c-max-width n-m-auto n-ptb-2">
            <router-link class="c-logo n-flex n-flex-a-c n-flex-j-sb n-ptb-1 n-pointer c-tab-link c-tab-link-event"
                         :to="{name: 'home'}">
                <a-image class="n-line-height-1"
                         :width="44"
                         :height="53.3"
                         :preview="false"
                         :src="require('@/assets/image/logo.png')" />
                <div class="n-flex-g">
                    <h1 class="n-c-4 n-line-height-1 c-f-1 n-m-0 n-ml-2">International Press</h1>
                    <h2 class="n-c-3 n-line-height-1 n-f-2 n-ml-2 n-m-0 n-mt-1">Publishers of scholarly mathematical and
                        scientific journals and books</h2>
                </div>
            </router-link>

            <ul class="c-menu n-p-0 n-ul n-flex n-flex-a-c n-m-0 n-flex-s">
                <li class="n-f-6 n-f-bold n-plr-4">
                    <router-link :to="{ name: 'home' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item c-tab-link c-tab-link-event">{{ LAN.nav.home }}</a>
                    </router-link>
                </li>

                <li class="n-f-6 n-f-bold n-plr-4 c-menu-with-children">
                    <router-link :to="{ name: 'journals' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item c-tab-sub-link c-tab-link-event">
                            <span>{{ LAN.nav.QQ }}</span>
                            <i class="n-icon n-icon-arrow-down n-inline-block n-icon-size-15 n-ml-1"></i></a>
                    </router-link>

                    <ul class="n-ul n-bg-1 c-shadow-1 n-radius-6 n-p-4">
                        <li>
                            <router-link :to="{ name: 'journal' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.QQHome }}</a>
                            </router-link>
                        </li>

                        <li class="n-mt-3">
                            <router-link :to="{ name: 'journalOnline' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.QQOnline }}</a>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="n-f-6 n-f-bold n-plr-4 c-menu-with-children">
                    <router-link :to="{ name: 'books' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item c-tab-sub-link c-tab-link-event"><span>{{ LAN.nav.book }}</span><i class="n-icon n-icon-arrow-down n-inline-block n-icon-size-15 n-ml-1"></i></a>
                    </router-link>

                    <ul class="n-ul n-bg-1 c-shadow-1 n-radius-6 n-p-4">
                        <li>
                            <router-link :to="{ name: 'book' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.bookHome }}</a>
                            </router-link>
                        </li>
                        <li class="n-mt-3">
                            <router-link :to="{ name: 'bookSuccession' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.bookSuccession }}</a>
                            </router-link>
                        </li>
                        <li class="n-mt-3">
                            <router-link :to="{ name: 'bookLight' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.bookLight }}</a>
                            </router-link>
                        </li>
                        <!-- <li class="n-mt-3">
                            <router-link :to="{name: 'bookList', query: {k: new Date().getTime()}}"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{'n-active': isActive}"
                                   class="n-col-1 n-block n-text-center n-c-3">{{LAN.nav.STY}}</a>
                            </router-link>
                        </li> -->
                    </ul>
                </li>
                <li class="n-f-6 n-f-bold n-plr-4">
                    <router-link :to="{ name: 'ordering' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item c-tab-link c-tab-link-event">{{ LAN.nav.ordering }}</a>
                    </router-link>
                </li>
                <li class="n-f-6 n-f-bold n-plr-4 c-menu-show">
                    <router-link :to="{ name: 'contact' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item c-tab-link c-tab-link-event">{{ LAN.nav.contactUs }}</a>
                    </router-link>
                </li>
                <li class="n-f-6 n-f-bold n-plr-4 c-menu-with-children">
                    <a class="c-menu-item c-tab-link c-tab-link-event"
                       href="javascript:;"
                       :class="{'n-c-base': $route.name == 'mine'}"
                       v-if="(userInfo.type || '') != ''">
                        <UserOutlined v-if="userInfo.type == 'personal'" />
                        <ApartmentOutlined v-else />
                        <span class="usename n-e-1 n-inline-block n-line-height-1 n-ml-1">{{ userInfo.nick_name || '-' }}</span>
                        <i class="n-icon n-icon-arrow-down n-inline-block n-icon-size-15 n-ml-1"></i>
                    </a>
                    <a class="c-menu-item n-c-base c-tab-link c-tab-link-event"
                       href="javascript:;"
                       v-else>{{ LAN.nav.login }}</a>

                    <ul class="n-ul n-bg-2 c-shadow-1 n-radius-6 n-plr-4 n-ptb-2"
                        style="width:200px">
                        <li class="n-mtb-2"
                            v-if="userInfo.type  == 'personal'">
                            <a href="javascript:;"
                               @click="$router.push({ name: 'mine' })"
                               class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{ LAN.nav.mine }}</a>
                        </li>

                        <li class="n-mtb-2"
                            v-if="(userInfo.type || '') == '' || (userInfo.type || '') == 'organization'">
                            <a @click="handlePersonalLogin"
                               href="javascript:;"
                               class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{LAN.nav.pLogin}}</a>

                        </li>
                        <li class="n-mtb-2"
                            v-if="((userInfo.type || '') == '' || (userInfo.type || '') == 'personal') && organiztionHasAuth">
                            <a @click="handleOrganizationLogin"
                               href="javascript:;"
                               class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{LAN.nav.oLogin}}</a>
                        </li>

                        <li class="n-mtb-2"
                            v-if="(userInfo.type || '') == 'personal' && ISGROUP == '0'">
                            <a @click="handleLogout"
                               href="javascript:;"
                               class="n-col-1 n-block n-text-center n-c-3 c-tab-link c-tab-link-event">{{LAN.nav.logout}}</a>
                        </li>
                    </ul>
                </li>
                <li class="c-menu-show">
                    <a-button type="primary"
                              shape="round"
                              class="c-menu-button n-ml-2"
                              @click="showMenu = !showMenu">
                        <template #icon>
                            <AlignRightOutlined />
                        </template>
                    </a-button>
                </li>
            </ul>
        </div>
    </div>

    <a-drawer v-model:visible="showCookie"
              placement="top"
              height="auto"
              :maskClosable="false"
              :closable="false">
        <p class="n-m-0">{{ LAN.cookie.notice }}</p>

        <div class="n-flex n-flex-j-e n-mt-2">
            <a-button class="n-mr-4"
                      @click="showCookieInfo = true">{{ LAN.cookie.more }}</a-button>
            <a-button type="primary"
                      @click="AC">{{ LAN.cookie.AC }}</a-button>
        </div>
    </a-drawer>

    <a-modal v-model:visible="showCookieInfo"
             centered
             width="90%"
             :title="LAN.cookie.title"
             :footer="null"
             @ok="showCookieInfo = false">

        <article class="n-scroll-v"
                 style="height: calc(90vh - 90px);"
                 v-if="LANI == 0">
            <p class="n-m-0">生效日期：2023 年4 月</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">引言</h2>
            <p class="n-m-0">International
                Press（以下或称“我们”）非常重视您的隐私保护，您在使用我们平台的产品和服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，我们如何收集、使用、存储及共享您的个人信息，以及您如何访问、更新、控制和保护您的个人信息。本《隐私政策》与您使用我们的服务关系紧密，希望您仔细阅读并理解，做出您认为适当的选择。您使用或继续使用我们的服务，即意味着您同意我们按照本指引内容收集、使用、储存及共享您的相关信息。如您对本《隐私政策》或相关事宜有任何问题，请通过发送邮件到lixin@intlpress.com与我们联系。
            </p>

            <h2 class="n-c-base n-mt-3 n-mb-0">本隐私政策将帮助您了解以下内容：</h2>
            <ul class="n-ul n-p-0 n-m-0">
                <li>一、我们如何收集个人信息</li>
                <li>二、我们如何使用个人信息</li>
                <li>三、我们如何存储和保护个人信息安全</li>
                <li>四、我们可能共享的信息</li>
                <li>五、未成年人隐私保护</li>
                <li>六、隐私政策的修订及变更</li>
            </ul>

            <h2 class="n-c-base n-mt-3 n-mb-0">一、我们如何收集个人信息</h2>
            <p class="n-m-0 n-mt-1">为了您可以正常使用我们的产品和服务，我们会在下列情形下收集您的相关个人信息：</p>

            <h4 class="n-m-0 n-mt-2">1.您向我们提供的信息</h4>
            <ul class="n-ul n-p-0 n-m-0">
                <li>（1）您在使用我们的产品和服务时，向我们提供的相关个人信息，例如姓名、电话号码、电子邮件等；</li>
                <li>（2）您通过我们的服务向其他方提供的共享信息，以及您使用我们的产品和服务时所提供、形成或储存的信息。</li>
            </ul>

            <h4 class="n-m-0 n-mt-2">2.我们收集到的您的信息</h4>
            <ul class="n-ul n-p-0 n-m-0">
                <li>（1）设备信息。为了提供更好的产品和服务并改善用户体验，我们会收集您的设备属性信息（例如您的设备型号、操作系统版本、设备配置、唯一设备标识符等软硬件特征信息），设备所在位置相关信息（如IP
                    地址、GPS位置、蓝牙或WIFI信号获得的位置信息）、设备连接信息（浏览器的类型、电信运营商、使用的语言）。</li>
                <li>（2）日志信息。当您使用我们的网站或客户端提供的产品和服务时，我们的服务器会自动记录一些信息作为有关网络日志保存。例如您对我们的产品和服务的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与通讯软件通讯的信息以及访问服务的日期、时间、时长等。
                </li>
                <li>（3）位置信息。当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们可能会收集和处理有关您实际所在位置的信息。我们会使用各种技术进行定位，这些技术包括
                    IP地址、GPS以及能够提供相关信息的其他传感器（比如可能会为我们提供附近设备、WIFI接入点和基站的信息）。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
                </li>
                <li>（4）本地存储。我们可能会使用浏览器网络存储等机制（包括HTML5）和APP应用数据缓存，收集您设备上的信息并进行本地存储。</li>
                <li>（5）Cookie和同类技术。我们可能会利用 Cookie 和同类技术（例如 Beacon、Proxy 等技术）了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验。我们承诺，我们不会将
                    Cookie用于本《隐私政策》所述目的之外的任何其他用途。您可以选择通过修改浏览器设置的方式拒绝Cookie，但这一操作在某些情况下可能会影响您安全访问和使用依赖于Cookie的中国网财经提供的服务或功能。
                </li>
            </ul>

            <h2 class="n-c-base n-mt-3 n-mb-0">二、我们如何使用个人信息</h2>
            <p class="n-m-0 n-mt-1">我们可能将在向您提供服务的过程中所收集的信息用作以下用途：</p>

            <h4 class="n-m-0 n-mt-2">1.提供和改进产品和服务</h4>
            <p class="n-m-0">我们使用收集的信息来提供并改进我们的产品和服务，帮助我们设计新的产品和服务。</p>

            <h4 class="n-m-0 n-mt-2">2.提供个性化产品和服务</h4>
            <p class="n-m-0">利用我们收集的信息，向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息、内容推荐信息、营销信息。</p>

            <h4 class="n-m-0 n-mt-2">3.保障产品和服务安全性</h4>
            <p class="n-m-0">我们可能使用您的信息用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性。</p>

            <h4 class="n-m-0 n-mt-2">4.经您许可的其他用途</h4>
            <p class="n-m-0">当我们要将您的个人信息用于其它用途时，我们会以通知确认的形式征求您的同意。</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">三、我们如何存储和保护个人信息安全</h2>

            <h4 class="n-m-0 n-mt-2">1.信息存储</h4>
            <p class="n-m-0">我们依照法律法规的规定，仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的个人信息。</p>

            <h4 class="n-m-0 n-mt-2">2.信息安全</h4>
            <p class="n-m-0">
                我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            </p>

            <h2 class="n-c-base n-mt-3 n-mb-0">四、我们可能共享的信息</h2>
            <p class="n-m-0">我们会以高度的勤勉义务对待您的信息，除以下情形外，未经您同意，我们不会向任何第三方共享您的信息：</p>

            <h4 class="n-m-0 n-mt-2">
                1.我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享，用作下列用途：
            </h4>
            <p class="n-m-0">（1）向您提供您所需要的服务或改进服务质量和个性化程度；</p>
            <p class="n-m-0">（2）为实现“我们如何使用个人信息”部分所述目的；</p>
            <p class="n-m-0">
                （3）履行我们在《用户协议》或本《隐私政策》中的义务和行使我们的权利；如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
            </p>

            <h4 class="n-m-0 n-mt-2">
                2.随着我们业务的持续发展，我们或我们的关联公司可能涉及合并、分立、收购、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并尽量确保在转移后继续受到其他隐私权政策的约束，您同意对此予以认可和理解。
            </h4>

            <h4 class="n-m-0 n-mt-2">3.我们或我们的关联公司也可能为了实现以下目的，访问、使用、保留或披露您的信息：</h4>
            <p class="n-m-0">（1）遵循任何适用法律法规的要求、法院裁决或判决、或政府机关的强制性要求；</p>
            <p class="n-m-0">（2）查找、预防或处理欺诈、安全或技术方面的问题；</p>
            <p class="n-m-0">（3）在法律法规要求或允许的范围内，保护我们的客户、我们的用户、我们或我们的关联公司、我们的雇员或社会公众的合法权益、财产或安全免遭损害。</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">五、未成年人隐私保护</h2>
            <p class="n-m-0">我们非常重视未成人的个人信息的保护，如果您是未成人，我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">六、隐私政策的修订及变更</h2>
            <p class="n-m-0">为更好的提供中国网财经的服务，根据用户需求以及法律法规的要求，我们可能适时修订本《隐私政策》的条款。我们将会通过予以公布或向您发送电子邮件或其他方式通知您。</p>
        </article>

        <article class="n-scroll-v"
                 style="height: calc(90vh - 90px);"
                 v-else>
            <p class="n-m-0">Effective Date: April 2023</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">Foreword</h2>
            <p class="n-m-0">International Press (hereinafter referred to as "us") places great importance on your privacy
                protection. When you use our platform's products and services, we may collect and use your relevant
                information. We hope to explain to you through this Privacy Policy how we collect, use, store, and share
                your personal information, as well as how you access, update, control, and protect your personal
                information. This Privacy Policy is closely related to your use of our services. We hope you can carefully
                read and understand it, and make the appropriate choices you consider appropriate. By using or continuing to
                use our services, you agree that we will collect, use, store, and share your relevant information in
                accordance with the content of this guide. If you have any questions about this Privacy Policy or related
                matters, please send an email to lixin@intlpress.com Contact us</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">This privacy policy will help you understand the following:</h2>
            <ul class="n-ul n-p-0 n-m-0">
                <li>A、How do we collect personal information</li>
                <li>B、How do we use personal information</li>
                <li>C、How do we store and protect personal information security</li>
                <li>D、Information that we may share</li>
                <li>E、Privacy protection for minors</li>
                <li>F、Revision and Change of Privacy Policy</li>
            </ul>

            <h2 class="n-c-base n-mt-3 n-mb-0">A、How do we collect personal information</h2>
            <p class="n-m-0 n-mt-1">In order for you to use our products and services normally, we will collect your
                relevant personal information under the following circumstances:</p>

            <h4 class="n-m-0 n-mt-2">1.The information you provided us with</h4>
            <ul class="n-ul n-p-0 n-m-0">
                <li>(1) The relevant personal information you provide us with when using our products and services, such as
                    name, phone number, email, etc;</li>
                <li>(2) The shared information you provide to other parties through our services, as well as the information
                    you provide, form, or store when using our products and services.</li>
            </ul>

            <h4 class="n-m-0 n-mt-2">2.We have collected your information</h4>
            <ul class="n-ul n-p-0 n-m-0">
                <li>(1) Device information. In order to provide better products and services and improve user experience, we
                    will collect information about your device attributes (such as your device model, operating system
                    version, device configuration, unique device identifier, and other software and hardware feature
                    information), as well as information about the location of the device (such as IP)</li>
                <li>(2) Log information. When you use the products and services provided by our website or client, our
                    server will automatically record some information as relevant network logs. For example, your usage of
                    our products and services, IP address, URL of the accessed service, browser type and language used,
                    information on downloading, installing or using mobile applications and software, communication with
                    communication software, and date, time, and duration of accessing the service.</li>
                <li>(3) Location information. When you enable the device positioning function and use our location-based
                    services, we may collect and process information about your actual location. We will use various
                    technologies for positioning, including IP addresses, GPS, and other sensors that can provide relevant
                    information (such as providing us with information about nearby devices, WIFI access points, and base
                    stations). You can stop collecting your geographic location information by turning off the positioning
                    function, but you may not be able to access relevant services or functions, or achieve the desired
                    results of the relevant services.</li>
                <li>(4) Local storage. We may use mechanisms such as browser network storage (including HTML5) and APP
                    application data caching to collect information on your device and store it locally.</li>
                <li>(5) Cookies and similar technologies. We may use cookies and similar technologies (such as Beacon,
                    Proxy, etc.) to understand your preferences and usage habits, conduct consultations or data analysis to
                    improve product services and user experience. We promise that we will not use cookies for any purpose
                    other than those stated in this Privacy Policy. You can choose to reject cookies by modifying browser
                    settings, but this operation may in some cases affect your safe access and use of services or features
                    provided by China Netfinance that rely on cookies.</li>
            </ul>

            <h2 class="n-c-base n-mt-3 n-mb-0">B、How do we use personal information</h2>
            <p class="n-m-0 n-mt-1">We may use the information collected during the provision of services to you for the
                following purposes:</p>

            <h4 class="n-m-0 n-mt-2">1.Provide and improve products and services</h4>
            <p class="n-m-0">We use the collected information to provide and improve our products and services, helping us
                design new products and services.</p>

            <h4 class="n-m-0 n-mt-2">2.Provide personalized products and services</h4>
            <p class="n-m-0">Using the information we collect, we recommend content that you may be interested in, including
                but not limited to sending you product and service information, content recommendation information, and
                marketing information.</p>

            <h4 class="n-m-0 n-mt-2">3.Ensuring product and service security</h4>
            <p class="n-m-0">We may use your information for authentication, customer service, security prevention, fraud
                monitoring, archiving, and backup purposes to ensure the security of the products and services we provide to
                you.</p>

            <h4 class="n-m-0 n-mt-2">4.Other uses approved by you</h4>
            <p class="n-m-0">When we want to use your personal information for other purposes, we will seek your consent
                through notification confirmation.</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">C、How do we store and protect personal information security</h2>

            <h4 class="n-m-0 n-mt-2">1.Information storage</h4>
            <p class="n-m-0">We will only retain your personal information for the period necessary for the purposes stated
                in this Privacy Policy and within the time limit required by laws and regulations, in accordance with the
                provisions of laws and regulations.</p>

            <h4 class="n-m-0 n-mt-2">2.Information safety</h4>
            <p class="n-m-0">We use various security technologies and procedures to prevent information loss, improper use,
                unauthorized reading or disclosure. For example, in certain services, we will use encryption technology to
                protect the personal information you provide. But please understand that due to technological limitations
                and various malicious means that may exist, even if every effort is made to strengthen security measures in
                the internet industry, it is impossible to always ensure 100% security of information. You need to
                understand that the system and communication network you use to access our services may encounter problems
                due to factors beyond our control.</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">D、Information that we may share</h2>
            <p class="n-m-0">We will treat your information with a high degree of diligence and obligation, and we will not
                share your information with any third party without your consent, except for the following situations:</p>

            <h4 class="n-m-0 n-mt-2">1.We and our affiliates may share your personal information with our affiliates,
                partners, and third-party service providers, contractors, and agents (such as communication service
                providers who send emails or push notifications on our behalf, map service providers who provide location
                data for us) for the following purposes:</h4>
            <p class="n-m-0">(1) Provide you with the services you need or improve the quality and personalization of
                services;</p>
            <p class="n-m-0">(2) To achieve the purpose stated in the section on "How We Use Personal Information";</p>
            <p class="n-m-0">(3) Fulfill our obligations and exercise our rights under the User Agreement or this Privacy
                Policy; If we or our affiliated companies share your personal information with any of the aforementioned
                third parties, we will strive to ensure that such third parties comply with this Privacy Policy and other
                appropriate confidentiality and security measures we require them to comply with when using your personal
                information.</p>

            <h4 class="n-m-0 n-mt-2">2.With the continuous development of our business, we or our affiliated companies may
                be involved in transactions such as mergers, divisions, acquisitions, acquisitions or sales of assets or
                businesses. Your personal information may be transferred as part of such transactions. We will ensure the
                confidentiality of such information at the time of transfer and strive to ensure that it continues to be
                subject to other privacy policies after transfer. You agree to acknowledge and understand this.</h4>

            <h4 class="n-m-0 n-mt-2">3.We or our affiliated companies may also access, use, retain, or disclose your
                information for the following purposes:</h4>
            <p class="n-m-0">(1) Comply with any applicable laws and regulations, court rulings or judgments, or mandatory
                requirements of government agencies;</p>
            <p class="n-m-0">(2) Identify, prevent, or address fraud, security, or technical issues;</p>
            <p class="n-m-0">(3) To protect the legitimate rights, property, or safety of our customers, our users, our
                affiliated companies, our employees, or the general public from harm to the extent required or permitted by
                laws and regulations.</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">E、Privacy protection for minors</h2>
            <p class="n-m-0">We attach great importance to the protection of personal information of minors. If you are a
                minor, we recommend that minors encourage their parents or guardians to read this Privacy Policy and seek
                their consent and guidance before submitting personal information.</p>

            <h2 class="n-c-base n-mt-3 n-mb-0">F、Revision and Change of Privacy Policy</h2>
            <p class="n-m-0">In order to better provide services for China Netfinance, we may revise the terms of this
                Privacy Policy in a timely manner based on user needs and legal and regulatory requirements. We will notify
                you by publishing or sending you an email or other means.</p>
        </article>
    </a-modal>

    <a-drawer v-model:visible="showMenu"
              placement="right"
              width="70%">
        <div class="n-h-1 n-flex n-flex-dr-c n-flex-a-e">
            <ul class="c-menu c-menu-mobile n-p-0 n-ul n-m-0 n-flex-g n-scroll-v auto"
                :class="showMenu ? 'n-active' : ''"
                style="max-height: 999px;">
                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right">
                    <router-link :to="{ name: 'home' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item">{{ LAN.nav.home }}</a>
                    </router-link>
                </li>

                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right c-menu-mobile-with-children">
                    <router-link :to="{ name: 'journals' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item">{{ LAN.nav.QQ }}<i class="n-icon n-icon-arrow-down n-inline-block n-icon-size-15 n-ml-1"></i></a>
                    </router-link>

                    <ul class="n-ul n-bg-1 n-bg-2 n-mt-2">
                        <li>
                            <router-link :to="{ name: 'journal' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-right n-c-3">{{
                                LAN.nav.QQHome }}</a>
                            </router-link>
                        </li>

                        <li>
                            <router-link :to="{ name: 'journalOnline' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-right n-c-3">{{
                                LAN.nav.QQOnline }}</a>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right c-menu-mobile-with-children">
                    <router-link :to="{ name: 'books' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item">{{ LAN.nav.book }}<i class="n-icon n-icon-arrow-down n-inline-block n-icon-size-15 n-ml-1"></i></a>
                    </router-link>

                    <ul class="n-ul n-bg-1 n-bg-2 n-mt-2">
                        <li>
                            <router-link :to="{ name: 'book' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-right n-c-3">{{
                                LAN.nav.bookHome }}</a>
                            </router-link>
                        </li>
                        <li class="n-mt-1">
                            <router-link :to="{ name: 'bookSuccession' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-right n-c-3">{{
                                LAN.nav.bookSuccession }}</a>
                            </router-link>
                        </li>
                        <li class="n-mt-1">
                            <router-link :to="{ name: 'bookLight' }"
                                         custom
                                         v-slot="{ href, isActive }">
                                <a :href="href"
                                   :class="{ 'n-active': isActive }"
                                   class="n-col-1 n-block n-text-right n-c-3">{{
                                LAN.nav.bookLight }}</a>
                            </router-link>
                        </li>
                        <!-- <li class="n-mt-1">
                        <router-link :to="{name: 'bookList', query: {k: new Date().getTime()}}"
                                     custom
                                     v-slot="{ href, isActive }">
                            <a :href="href"
                               :class="{'n-active': isActive}"
                               class="n-col-1 n-block n-text-right n-c-3">{{LAN.nav.STY}}</a>
                        </router-link>
                    </li> -->
                    </ul>
                </li>
                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right">
                    <router-link :to="{ name: 'ordering' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           :class="{ 'n-active': isActive }"
                           class="c-menu-item">{{ LAN.nav.ordering }}</a>
                    </router-link>
                </li>
                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right">
                    <router-link :to="{ name: 'contact' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           class="n-c-3"
                           :class="{ 'n-active': isActive }">{{ LAN.nav.contactUs }}</a>
                    </router-link>
                </li>
                <li class="n-f-6 n-f-bold n-ptb-2 n-text-right">
                    <router-link :to="{ name: 'mine' }"
                                 custom
                                 v-slot="{ href, isActive }">
                        <a :href="href"
                           class="n-c-3"
                           :class="{ 'n-active': isActive }">{{ LAN.nav.mine }}</a>
                    </router-link>
                </li>
            </ul>

            <div class="n-f-6 n-f-bold n-ptb-2 n-text-right n-bg-2 n-col-1">
                <a class="c-menu-item n-c-base n-plr-4"
                   v-if="(userInfo.type || '') != ''">
                    <UserOutlined v-if="userInfo.type == 'personal'" />
                    <ApartmentOutlined v-else />
                    {{ userInfo.nick_name || '-' }}
                </a>

                <ul class="n-ul n-bg-1 n-bg-2 n-mb-0 n-plr-4"
                    style="max-height: 999px">
                    <li class="n-mt-2"
                        v-if="(userInfo.type || '') == '' || (userInfo.type || '') == 'organization'">
                        <a @click="handlePersonalLogin"
                           class="n-col-1 n-block n-text-right n-c-3">{{LAN.nav.pLogin}}</a>

                    </li>
                    <li class="n-mt-2"
                        v-if="((userInfo.type || '') == '' || (userInfo.type || '') == 'personal') && organiztionHasAuth">
                        <a @click="handleOrganizationLogin"
                           class="n-col-1 n-block n-text-right n-c-3">{{LAN.nav.oLogin}}</a>
                    </li>

                    <li class="n-mt-2"
                        v-if="(userInfo.type || '') == 'personal' && ISGROUP == '0'">
                        <a @click="handleLogout"
                           class="n-col-1 n-block n-text-right n-c-3">{{LAN.nav.logout}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { AlignRightOutlined, UserOutlined, ApartmentOutlined } from '@ant-design/icons-vue';
    import { Modal } from 'ant-design-vue'


    import nui from '@/assets/script';

    export default {
        name: 'Header',
        props: {
            organiztionHasAuth: { type: Boolean, default: false },
            userInfo: { type: Object, default: { nick_name: "", type: "" } }
        },
        computed: {
            ...mapState(nui.extendVue.extendState.concat(['location', 'ISGROUP'])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                showMenu: false,
                showCookie: false,
                showCookieInfo: false,
                organiztionHasAuth: false
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.showCookie = !localStorage.getItem(this.PREFIX + 'AC');
            },
            AC () {
                localStorage.setItem(this.PREFIX + 'AC', 1)
                this.showCookie = false
            },
            handlePersonalLogin () {
                this.$emit("personallogin");
            },
            handleOrganizationLogin () {
                Modal.confirm({
                    content: this.LAN.nav.changeLogin,
                    centered: true,
                    onOk: () => {
                        this.$emit("organizationlogin");
                    }
                })
            },
            handleLogout () {
                this.$emit("logout");
            }
        }),
        components: {
            AlignRightOutlined,
            ApartmentOutlined,
            UserOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss">
    .n-header {
        position: relative;
        z-index: 20;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);

        .ant-image {
            line-height: 1 !important;
        }

        .c-logo {
            position: relative;
            z-index: 2;

            h2 {
                width: 240px;
            }
        }

        .c-menu-button {
            display: none;
        }
    }

    .c-menu-with-children {
        position: relative;

        &.c-tab-link-focus-parent,
        &:hover {
            ul {
                transform: translateX(-50%) scale(1);

                li a {
                    &:hover,
                    &.n-active {
                        color: $color-base;
                    }
                }
            }
        }

        ul {
            transform-origin: center top;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) scale(0);
            width: 260px;
            transition: all 0.3s;

            li {
                height: 25px;
                line-height: 25px;
            }
        }
    }

    .c-menu {
        & > li {
            .c-menu-item {
                color: $font-color-3;
                position: relative;
                transition: color 0.3s;

                &.n-c-base {
                    color: $color-base;
                }

                &:after {
                    z-index: 10;
                    content: "";
                    transition: all 0.3s;
                    height: 2px;
                    width: 100%;
                    left: 0;
                    transform: scaleX(0);
                    position: absolute;
                    bottom: -10px;
                    background-color: $color-base;
                    display: block;
                    transform-origin: 50% 50%;
                }
            }

            &:hover .c-menu-item,
            .c-menu-item.n-active {
                color: $color-base;

                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }

    article {
        h2,
        h4 {
            font-weight: bold;
        }

        li,
        p {
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 1100px) {
        .n-header {
            .n-header-inner {
                flex-direction: column;
            }

            .c-logo {
                box-shadow: $shadow;
                width: 100%;
            }

            .c-menu {
                justify-content: space-between;
                width: 100%;
                padding: 10px 10px 0 10px !important;

                & > li {
                    display: none;
                    line-height: 1.5;
                    padding: $margin-padding-1;
                    margin-bottom: $margin-padding-2;
                    position: relative;

                    &.c-menu-show {
                        display: block;
                    }
                }
            }

            .c-menu-button {
                display: block;
            }
        }

        .c-menu-mobile {
            padding: 0 !important;

            li {
                line-height: 1.5;
                padding: $margin-padding-1;
                margin-bottom: $margin-padding-2;
                position: relative;

                a {
                    display: block;

                    &:after {
                        width: 50%;
                        right: 0;
                        left: auto;
                        bottom: -5px;
                        transform: scaleX(0);
                    }
                }

                a.n-active {
                    color: $color-base;

                    &:after {
                        transform: scaleX(1);
                    }

                    & + ul {
                        max-height: 999px;
                    }
                }

                &.c-menu-mobile-with-children > ul {
                    transition: all 0.3s;
                    max-height: 0;
                    overflow: hidden;
                }
            }

            &.n-active {
                padding: $margin-padding-2 $margin-padding-3;
                max-height: 300px;
            }
        }
    }

    .usename {
        max-width: 120px;
        transform: translateY(3px);
    }
</style>