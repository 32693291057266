import request from 'axios'; // 异步请求模块
import config from './config'; // 配置文件
import extend from 'extend'; // 类jQuery.extend()
import XSS from 'xss';//过滤XSS攻击
import context from '../../main'; // 获取全局VUE引用
import cancelRequest from 'axios-cancel'; // 中断异步请求模块
import LAN from '../../assets/script/LAN';
import MD5 from 'md5';
import { createVNode } from 'vue';
import { getStore } from '@/assets/script/store';
import { getToken, getAdminToken } from '@/assets/script/auth'

cancelRequest(request);

let nui = {
    extend,
    XSS,
    MD5,
    /**
     * @description 判断是否是手机号
     * @param {String|Number} phoneNumber 手机号码
     * @returns {Boolean}
     */
    isPhoneNumber: function (phoneNumber) {
        var pattern = /^1[34578]\d{9}$/;
        return pattern.test(phoneNumber);
    },
    /**
     * @description 判断是否是Email
     * @param email Email符串
     * @returns {boolean}
     */
    isEmail: function (email) {
        var pattern = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        return pattern.test(email)
    },
    /**
     * @description 判断是否是符合密码格式
     * @param password  密码字符串
     * @returns {boolean}
     */
    isPassword: function (password) {
        if ($.strIsNull(password + '')) {
            return false
        }

        var pattern = /^[^\s]{6,16}$/;
        return pattern.test(password);
    },
    /**
     * @description 通过文件后缀判断是否是图片
     */
    isImageByName (name) {
        var ext = name.substring(name.lastIndexOf('.'), name.length).toLowerCase();

        return ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff'].indexOf(ext) >= 0
    },
    /**
     * @description 通过文件后缀判断是否是视频
     */
    isVideoByName (name) {
        var ext = name.substring(name.lastIndexOf('.'), name.length).toLowerCase();

        return ['.mp4', '.mov'].indexOf(ext) >= 0
    },
    /**
     * @description 通过文件后缀判断是否是音频
     */
    isAudioByName (name) {
        var ext = name.substring(name.lastIndexOf('.'), name.length).toLowerCase();

        return ['.mp3'].indexOf(ext) >= 0
    },
    /**
     * @description 根据路径获取文件名
     * @param {*} url 
     * @returns 
     */
    getFileNameByUrl (url) {
        return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
    },
    /**
     * @description 根据路径获取后缀
     * @param {*} url 
     * @returns 
     */
    getFileExtByUrl (url) {
        return url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase();
    },
    /**
     * @description     检查是否符合身份证格式
     * @param number
     * @returns {boolean}
     */
    isID: function (number) {
        /*
            该方法由网友提供;
            对身份证进行严格验证;
        */
        var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子;
        var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值，10代表X;

        if (number.length == 15) {
            return isValidityBrithBy15IdCard(number);
        } else if (number.length == 18) {
            var a_idCard = number.split(""); // 得到身份证数组
            if (isValidityBrithBy18IdCard(number) && isTrueValidateCodeBy18IdCard(a_idCard)) {
                return true;
            }
            return false;
        }
        return false;

        function isTrueValidateCodeBy18IdCard (a_idCard) {
            var sum = 0; // 声明加权求和变量
            if (a_idCard[17].toLowerCase() == 'x') {
                a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
            }
            for (var i = 0; i < 17; i++) {
                sum += Wi[i] * a_idCard[i]; // 加权求和
            }
            valCodePosition = sum % 11; // 得到验证码所位置
            if (a_idCard[17] == ValideCode[valCodePosition]) {
                return true;
            }
            return false;
        }

        function isValidityBrithBy18IdCard (idCard18) {
            var year = idCard18.substring(6, 10);
            var month = idCard18.substring(10, 12);
            var day = idCard18.substring(12, 14);
            var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
            // 这里用getFullYear()获取年份，避免千年虫问题
            if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
                return false;
            }
            return true;
        }

        function isValidityBrithBy15IdCard (idCard15) {
            var year = idCard15.substring(6, 8);
            var month = idCard15.substring(8, 10);
            var day = idCard15.substring(10, 12);
            var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
            // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
            if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
                return false;
            }
            return true;
        }

    },
    /**
     * @description 判断参数是否为空 包括 "" || null || undefined || "undefined" || "null"
     * @param str
     * @returns {Boolean}
     */
    strIsNull: function (str) {
        return str === '' || str === null || str === undefined || str === 'undefined' || str === 'null';
    },
    /**
     * @description 判断是否是微信浏览器环境
     * @returns {Boolean}
     */
    isWXBrowser: function () {
        return navigator.userAgent.toLowerCase().includes('micromessenger');
    },
    /**
     * @description 判断是否是IOS
     * @returns {Boolean}
     */
    isIOS: function () {
        return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    /**
     * @description 判断是否移动端
     * @returns {Boolean}
     */
    isMobile () {
        return document.body.ontouchstart !== undefined
    },
    /**
     * @description 获取url中的query参数
     * @param str 指定的url字符串，默认值location.search
     * @returns {JSON|Object}
     */
    queryUrlParam: function (str) {
        var url = str || location.search,
            param = {},
            paramUrl;

        if (url.indexOf('?') >= 0) {
            paramUrl = url.substr(url.indexOf('?') + 1);
        } else {
            paramUrl = url
        }

        var paramUrlArr = paramUrl.split('&');

        for (var i = 0; i < paramUrlArr.length; i++) {
            param[paramUrlArr[i].split('=')[0]] = decodeURIComponent(paramUrlArr[i].split('=')[1]);
        }

        return param;
    },
    /**
     * @description 设置IOS端键盘弹出收起后页面底部出现空白的问题，此函数在input.blur()事件触发后调用
     */
    IOSKeyboardHideHack: function () {
        document.body.scrollTop += 1;
        document.documentElement.scrollTop += 1;
    },
    /**
     * @description 路由切换的时候取消当前页面的ajax 
     */
    cancelAjaxWhenRouterChange (store, to, from) {
        for (let id in store.state.pageRequestIdCache[from.name]) {
            store.commit('removePageRequestIdCache', { page: from.name, id })
            request.cancel(id);
        }
    },
    /**
     * @description使所有requerst-disabled按钮可用/不可用 
     */
    requestDisabled (status) {
        var buttons = document.querySelectorAll('[request-disabled]');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].disabled = status
        }
    },
    parseUTF (data) {
        if (typeof data === 'string') {
            return data.replace(/&amp;#([0-9A-Xa-x]+);/g, function (match, dec) {
                return '&#' + dec;
            })
        } else if (Array.isArray(data)) {
            return data.map(item => this.parseUTF(item));
        } else if (typeof data === 'object' && data !== null) {
            let newData = {};
            for (let key in data) {
                newData[key] = this.parseUTF(data[key]);
            }
            return newData;
        } else {
            return data;
        }
    },
    isJSON (obj) {
        return typeof obj === 'object' && obj !== null;
    },
    /**
     * @description 异步请求函数
     * @param {String} url 请求地址
     * @param {JSON|Object} param 具体参数详见代码
     */
    request (url, param = {}) {
        let finalUrl = (url.indexOf('http') >= 0 ? '' : (config.HOST + config.BASE)) + url;
        let dataIsFormData = param.data instanceof FormData,
            loadingObject,
            penddingNoticeTot,
            defaultParam = {
                url: finalUrl, // 请求地址
                method: 'get',// 请求方法
                charset: 'utf-8',// 请求字符集
                data: {},// POST请求的参数
                params: {},// GET请求的URL Query参数
                responseType: 'json',// 返回数据类型
                timeout: 30000,// 超时时间
                autoCancel: true,
                processData: false,
                requestId: 'defaultRequestId-' + new Date().getTime() + Math.random(),// 请求ID，方式多次触发同一个请求，若不传此参数则不作去重处理
                checkAuth: true,// 强制判断是否需要鉴权
                loading: false,// 是否启用加载中提示
                requestDisabled: undefined,//1-请求开始Disabled，结束Enable   2-请求开始Disabled，请求失败Enable
                pendingNoticeEnable: false,//是否显示超时提示信息
                pendingNotice: LAN.language.nui.common.timeout,//超时提示信息
                pendingNoticeTimer: 7000,//超时提示信息的时间阙值

            };


        let token = getToken();
        let adminToken = getAdminToken();
        // debugger;
        let defaulthHeaders = {
            'Content-Type': dataIsFormData ? 'multipart/form-data' : 'application/json',
            // 'TOKEN': this.getToken(),
            'Bgcloud-Auth': 'bearer ' + token,
            'Admin-Auth': 'bearer ' + adminToken,
            'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
            "Tenant-Id": getStore({ name: 'tenantId' }) || ''
            // 'Cache-Control': 'no-cache',
            // 'Authorization': "Bearer " + this.getToken(),// 用户授权Token
            // 'AppID': config.APPID,
            // 'AppVersion': CONFIG.VERSION,
            // 'HostName': nui.deviceID()
        };

        defaulthHeaders = Object.assign(defaulthHeaders, param.headers || {});


        // 整合默认参数和自定义参数
        param = Object.assign(defaultParam, param);
        param.headers = defaulthHeaders;

        param.params = filterPostXSS(param.params)

        // 将timeout统一转化为秒(单位)
        param.timeout = param.timeout / (this.isAPICloud() ? 1000 : 1);

        // 统一处理APICloud参数
        if (this.isAPICloud()) {
            param.tag = param.requestId;
            param.dataType = param.responseType;
            param.returnAll = true;
            param.data = {
                body: this.extend({}, param.data, param.params)
            };
        }

        if (!dataIsFormData) {
            param.data = filterPostXSS(param.data)
        }

        // 如果存在加载中提示则渲染加载界面
        if (param.loading) {
            loadingObject = NLoading.loading({ type: param.loading });

            if (param.requestDisabled == undefined) {
                param.requestDisabled = 1
            }
        }

        //请求开始Disbaled按钮
        if (param.requestDisabled) {
            setTimeout(() => {
                this.requestDisabled(true)
            }, 0);
        }

        // 剔除多余参数
        delete param.loading;

        // 取消已经再请求的相同请求，设置了自定义requestId的生效，否则不生效
        request.cancel(param.requestId);

        //超过一定时间提示请求中提示信息
        if (param.pendingNoticeEnable) {
            penddingNoticeTot = setTimeout(() => {
                NToast.toast(param.pendingNotice)
            }, param.pendingNoticeTimer);
        }

        //缓存请求ID
        context && param.autoCancel && context.store.commit('addPageRequestIdCache', { page: context.router.currentRoute.value.name, id: param.requestId })

        let ajaxDoneAfter = () => {
            // 请求完毕不论是否成功，都移除加载界面
            loadingObject && loadingObject.close();

            //请求完毕关闭提示信息
            penddingNoticeTot && clearTimeout(penddingNoticeTot)

            //移除请求ID缓存
            context && context.store.commit('removePageRequestIdCache', { page: context.router.currentRoute.value.name, id: param.requestId })
        }, ajaxSuccess = (data) => {
            if (typeof data != 'object') {
                return
            }

            // 整理下数据，后端返回的数据总是不一样
            if (!this.strIsNull(data.code)) {
                data.Code = data.code
            }

            if (!this.strIsNull(data.Code)) {
                data.code = data.Code
            }

            // 如果授权失败则跳转到登录页面
            if (param.checkAuth && data && (data.Code == config.AUTH_ERROR_CODE || data.code == config.AUTH_ERROR_CODE || data.status == config.AUTH_ERROR_CODE || data.Status == config.AUTH_ERROR_CODE)) {
                loadingObject && loadingObject.close();
                nui.goLogin()
                return
            }

            //请求成功是否恢复Disabled的按钮
            if (data && (data.Code == config.REQUEST_SUCCESS_CODE || data.code == config.REQUEST_SUCCESS_CODE || data.status == config.REQUEST_SUCCESS_CODE || data.Status == config.REQUEST_SUCCESS_CODE)) {
                if (param.requestDisabled == 1) {
                    this.requestDisabled(false)
                }
            } else {
                if (!data.msg) {
                    data.msg = createVNode('div', {}, [createVNode('p', {}, '服务器错误，请联系服务器管理员'), createVNode('p', {}, '错误位置：' + param.url)])
                }

                if (param.requestDisabled != undefined) {
                    this.requestDisabled(false)
                }
            }
        }, ajaxError = (err) => {
            if (!err) {
                return
            }
            if (err.status == 401 && param.url != 'login') {
                loadingObject && loadingObject.close();
                nui.goLogin()
            }

            //请求失败恢复Disabled的按钮
            if (param.requestDisabled != undefined) {
                this.requestDisabled(false)
            }
            // 请求完毕不论是否成功，都移除加载界面
            loadingObject && loadingObject.close();
            //请求完毕关闭提示信息
            penddingNoticeTot && clearTimeout(penddingNoticeTot)

            //移除请求ID缓存
            context && context.store.commit('removePageRequestIdCache', { page: context.router.currentRoute.value.name, id: param.requestId })


            if (this.isAPICloud()) {
                // NToast.toast(err.data[Object.keys(err.data)[0]])
            } else {
                // if (err.request.readyState == 4 && err.request.status == 0) {
                //     // NToast.toast(LAN.language.nui.common.timeoutError)
                // }
            }
        }

        // 发起请求
        return new Promise((resolve, reject) => {
            if (this.isAPICloud()) {
                console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')
                console.log(JSON.stringify(param))
                console.log('----------------------------------')
                api.ajax(param, function (res, err) {
                    if (res) {
                        resolve(res.body)
                        ajaxSuccess(res.body);
                        ajaxDoneAfter();
                    } else {
                        err.data = err.body;

                        reject(err);
                        ajaxError(err)
                        ajaxDoneAfter();
                    }
                });
            } else {
                request(param).then((res) => {
                    var data = res.data || res.Data;

                    if (this.isJSON(data)) {
                        data = this.parseUTF(res.data || res.Data)
                    }

                    resolve(data)
                    ajaxSuccess(data)
                    ajaxDoneAfter()
                }).catch((err) => {
                    reject(err)
                    ajaxError(err)
                    ajaxDoneAfter()
                });
            }
        })


        function filterPostXSS (origin) {
            var isObject = typeof origin == 'object' && !Array.isArray(origin),
                originCopy = extend(isObject ? {} : [], origin);

            for (var i in originCopy) {
                if (typeof originCopy[i] == 'object') {
                    if (originCopy[i] === null) {
                        originCopy[i] = null;
                    } else {
                        originCopy[i] = filterPostXSS(originCopy[i])
                    }
                } else {
                    originCopy[i] = originCopy[i];  // 需要处理类型的问题 XSS(originCopy[i])
                }
            }

            return originCopy
        }
    },
    /**
     * @description 解析日期字符串
     * @param {Object|Number} stamp
     * @returns {JSON|Object} {year, month, day, hour, minute, second}
     */
    parseDate: function (param) {
        var timeObject = (typeof param === 'object' && param.getTime()) ? param : new Date(param);

        if (!timeObject.getTime()) {
            console.error('parseDate:参数错误');
            return;
        }

        var year = timeObject.getFullYear(),
            month = (timeObject.getMonth() + 1).toString(),
            day = timeObject.getDate().toString(),
            hour = timeObject.getHours().toString(),
            minute = timeObject.getMinutes().toString(),
            second = timeObject.getSeconds().toString();

        // 格式化一位数的时间字符串
        month.length < 2 && (month = '0' + month);
        day.length < 2 && (day = '0' + day);
        hour.length < 2 && (hour = '0' + hour);
        minute.length < 2 && (minute = '0' + minute);
        second.length < 2 && (second = '0' + second);

        return { year: year + '', month: month, day: day, hour: hour, minute: minute, second: second };
    },
    /**
     * @description 日期格式化20240605
     * @param {Object|Number} stamp
     * @returns {JSON|Object} {year, month, day, hour, minute, second}
     */
    parseDateFormat: function (inputDate) {
        if (inputDate) {
            // 将输入日期字符串转换为 Date 对象
            // const date = new Date(inputDate);
            const date = this.createDateWithNoTimezone(inputDate);

            console.log("inputDate", inputDate, "date", date);

            // 检查是否是有效的日期对象
            if (isNaN(date)) {
                throw new Error('Invalid Date');
            }

            // 获取年份
            const year = date.getFullYear();

            // 获取月份名称（简写）
            const month = date.toLocaleString('en-US', { month: 'long' });

            // 获取日期
            const day = date.getDate();

            // 返回格式化后的日期字符串
            return `${day} ${month} ${year}`;
        } else {
            return '';
        }
    },

    formatDate: function (inputDate, formatter) {
        if ((formatter || '') == '') {
            formatter = 'yyyy/MM/dd HH:mm:ss';
        }
        if (inputDate) {
            // 将输入日期字符串转换为 Date 对象
            const date = new Date(inputDate);

            // 检查是否是有效的日期对象
            if (isNaN(date)) {
                return "";
            }

            // 获取年份
            const year = date.getFullYear();

            // 获取月份名称（简写）
            const month = date.getMonth() + 1;

            // 获取日期
            const day = date.getDate();

            let hour = date.getHours();
            if (hour < 10) {
                hour = '0' + hour;
            }

            let minute = date.getMinutes();
            if (minute < 10) {
                minute = '0' + minute;
            }

            let second = date.getSeconds();
            if (second < 10) {
                second = '0' + second;
            }

            // 返回格式化后的日期字符串
            switch (formatter) {
                case 'yyyy/MM/dd HH:mm:ss':
                    return `${year}/${month}/${day} ${hour}:${minute}:${second}`;

                case 'yyyy/MM/dd':
                    return `${year}/${month}/${day}`;

                case 'yyyy-MM-dd HH:mm:ss':
                    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;

                case 'yyyy-MM-dd':
                    return `${year}-${month}-${day}`;
            }

        } else {
            return '';
        }
    },

    formatDateToLocalTime: function (inputDate, formatter) {
        let date = this.formatDate(inputDate, formatter);
        return date + ' Local Time';
    },

    createDateWithNoTimezone: function (dateString) {
        const localDate = new Date(dateString);
        const localTime = localDate.getTime();
        const localOffset = localDate.getTimezoneOffset() * 60 * 1000;
        // const targetOffset = timezoneOffset * 60 * 1000;
        const targetTime = localTime + localOffset;
        return new Date(targetTime);
    },
    /**
     * @description 获取
     * @returns {String} 主体名称
     */
    getTheme: function () {
        var cacheTheme = localStorage.getItem(config.PREFIX + 'THEME');
        return cacheTheme || 'DARK-BLUE';
    },
    /**
     * @description 设置主题
     * @param {String} theme 主体名称
     */
    setTheme: function (theme) {
        localStorage.setItem(config.PREFIX + 'THEME', theme);
    },
    /**
     * @description 初始化主题到页面
     */
    initTheme: function () {
        var cacheTheme = this.getTheme();

        document.getElementsByTagName('html')[0].className = 'theme-' + cacheTheme + ' ' + cacheTheme.split('-')[0];
    },
    /**
     * @description 剔除字符串中的空格
     * @param {String} str 字符串 
     * @param {Number} type 剔除模式0,1,2,3 0剔除首空格，1剔除尾空格，2剔除首尾空格，3剔除所有空格，默认剔除所有
     */
    trims: function (str, type = 3) {
        if (typeof str !== 'string') {
            console.error('trim:参数必须为字符串');
            return
        }

        if (type == 0 || type == 2) {
            str = str.replace(/^\s+/g, '');
        }

        if (type == 1 || type == 2) {
            str = str.replace(/\s+$/g, '');
        }

        if (type == 3) {
            str = str.replace(/\s+/g, '');
        }

        return str;
    },
    /**
     * @description 根据索引删除数组的元素并返回新数组
     * @param {Array} arr 旧的数组 
     * @param {Number} index 删除的索引
     * @returns {Array} 删除后的新数组 
     */
    removeByIndexFromArray: function (arr, index) {
        if (arr === undefined) {
            return []
        }

        if (index === undefined) {
            return arr
        }

        var pre = arr.slice(0, index),
            nxt = arr.slice(index + 1, arr.length);

        return pre.concat(nxt);
    },
    /**
     * @description JSON按照key排序
     * @param {JSON} obj 
     */
    sortJSONByKey (obj) {
        let keys = Object.keys(obj).sort(), newObj = {}

        for (let i = 0; i < keys.length; i++) {
            newObj[keys[i]] = obj[keys[i]];
        }

        return newObj;
    },
    /**
     * @description 获取文件绝对路径
     * @param {File} file 
     */
    getFileURL (file) {
        if (window.createObjcectURL != undefined) {
            return window.createOjcectURL(file);
        } else if (window.URL != undefined) {
            return window.URL.createObjectURL(file);
        } else if (window.webkitURL != undefined) {
            return window.webkitURL.createObjectURL(file);
        }
    },
    /**
     * @description 检查Hash History变动，并延时执行回调
     */
    checkHistoryChange (callback) {
        window.checkHistoryChangeTot && clearTimeout(window.checkHistoryChangeTot);

        window.checkHistoryChangeTot = setTimeout(() => {
            callback && callback();
        }, 100);
    },
    /**
     * @description 重置Hash History
     */
    resetPreventDefault (callback) {
        var historyLength = this.getPreventDefaultHash().hash.length;

        if (historyLength > 0) {
            window.history.go(-1 * (this.getPreventDefaultHash().hash.length))
        }

        setTimeout(() => {
            callback && callback()
        }, 300);
    },
    /**
     * @description 阻止页面默认的后退并在后退时触发回调函数
     * @param name 历史记录Name值
     */
    preventDefaultBack (name) {
        name = name || new Date().getTime();

        if (this.getPreventDefaultHash().hash.indexOf(name) < 0) {
            window.location.hash += '#' + name;
        }
    },
    /**
     * @description 获取当前页面Hash History
     */
    getPreventDefaultHash () {
        var hash = window.location.hash.split('#');

        hash.shift();

        return {
            hash,
            currentHash: hash[hash.length - 1]
        }
    },
    /**
         * @description 升级四舍五入可保留小数位数
         * @param {*} number 
         * @param {*} precision 
         */
    mathRound (number, precision = 2) {
        return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
    },



    //---------------------------------以下是APICloud兼容函数
    /**
     * @description 判断是否是APICloud环境
     */
    isAPICloud () {
        return navigator.userAgent.toLowerCase().indexOf('apicloud') >= 0;
    },

    /**
     * @description 获取状态栏高度
     * @returns {Object}
     */
    safeArea () {
        if (this.isAPICloud()) {
            return {
                top: api.safeArea.top,
                bottom: api.safeArea.bottom
            };
        } else {
            return { top: 0, bottom: 0 }
        }
    },
    /**
     * @description 获取设备码
     * @returns 
     */
    deviceID () {
        if (this.isAPICloud()) {
            // APICloud返回设备唯一码，设备标识
            return new UA().getResult().ua + '[' + api.deviceId + '][' + api.deviceName + ']';
        } else {
            // 移动端无唯一设备码，返回UserAgent
            return new UA().getResult().ua + "[Browser]"
        }
    },
    /**
     * @description 引用APICloud模块
     * @param {String} name 
     */
    require (name) {
        if (context[name]) {
            return AR[name];
        } else if ($.isAPICloud()) {
            return api.require(name);
        }
    },




    //---------------------------------此部分为User相关---Start
    /**
     * @description 根据Token缓存判断是否登录
     * @returns {Boolean}
     */
    isLogin: function () {
        return !!this.getUserInfo();
    },
    /**
     * @description 获取Token缓存
     * @returns {String}
     */
    // getToken: function () {
    //     return this.getUserInfo() ? this.getUserInfo().token : null;
    // },
    /**
     * @description 获取用户信息
     * @returns {JSON|Object|Null} 
     */
    getUserInfo: function () {
        const info = localStorage.getItem(config.PREFIX + 'USER_INFO');
        try {
            return JSON.parse(info);
        } catch (e) {
            return null;
        }
    },
    /**
     * @description 跳转到登录页面
     */
    goLogin () {
        this.removeUserInfo()
        context.router.replace({ name: 'login' });
    },
    /**
     * @description 缓存用户信息
     * @param {JSON|Object} data 
     */
    setUserInfo: function (data) {
        context.store.commit('setUserInfo', data);
        window.localStorage.wmUserInfo = JSON.stringify({ userId: data.adName, userTag: data.username, projectVersion: '1.0.0', env: 'pro' })
        localStorage.setItem(config.PREFIX + 'USER_INFO', JSON.stringify(data));
    },
    /**
     * @description
     */
    removeUserInfo: function (data) {
        context.store.commit('removeUserInfo', data);
        localStorage.removeItem(config.PREFIX + 'USER_INFO');
    },
    //---------------------------------此部分为User相关---End
};

export default nui;
