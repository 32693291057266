import extend from 'extend';
import config from './config';

const LAN = {
    APPName: ['International Press of Boston', 'International Press of Boston'],
    welcome: ['Welcome to International Press of Boston', 'Welcome to International Press of Boston'],
    welcomeBR: ['Welcome to<br>International Press of Boston', 'Welcome to<br>International Press of Boston'],

    languageStr: {
        0: ['中文', '中文'],
        1: ['English', 'English']
    },

    journal: {
        openAccess: ['Open access', 'Open access'],
        home: ['Journals Home', 'Journals Home'],
        editors: ['Editors', 'Editors'],
        sEditors: ['Series Editors', 'Series Editors'],
        submissions: ['Submissions', 'Submissions'],
        accepted: ['Accepted', 'Accepted'],
        onlines: ['Read Online', 'Read Online'],
        peryear: ['|US| issues per year, ', '|US| issues per year, '],
        peryear1: ['|US| issues per year.', '|US| issues per year.'],

        vuewAuthorPub: ['View author publications', 'View author publications'],

        firstArticle: ['Online First', 'Online First'],
        firstArticles: ['Online First Articles', 'Online First Articles'],

        since: ['|US| issues per year. Since |YEAR|.', '|US| issues per year. Since |YEAR|.'],

        sinces: ['Since |YEAR|.', 'Since |YEAR|.'],
        sincep: ['|US| issues per year. ', '|US| issues per year. '],

        since1: ['Annual Book. Since |YEAR|.', 'Annual Book. Since |YEAR|.'],
        print: ['ISSN Print |PRINT|', 'ISSN Print |PRINT|'],
        online: ['ISSN Online |ONLINE|', 'ISSN Online |ONLINE|'],
        gotoCDM: ['Go to |NAME| contents online.', 'Go to |NAME| contents online.'],
        chief: ['Editor-in-Chief', 'Editor-in-Chief'],
        editor: {
            name: ['Name', 'Name'],
            affilliation: ['Affiliation', 'Affiliation'],
            contact: ['Contact', 'Contact'],
            webPage: ['Web Page', 'Web Page'],
            expertise: ['Expertise', 'Expertise'],
        },
        editorLabel: {
            'EDITOR-IN-CHIEF': ['Editor-in-Chief', 'Editor-in-Chief'],
            'MGMT-B-C': ['Mgmt. Board Chairs', 'Mgmt. Board Chairs'],
            'MGMT-B-M': ['Management Board Members', 'Management Board Members'],
            'EXECUTIVE-EDITOR': ['Executive Editor', 'Executive Editor'],
            'CONSULTING-EDITOR': ['Consulting Editor', 'Consulting Editor'],
            'EABM': ['Editorial Advisory Board Member', 'Editorial Advisory Board Member'],
            'MANAGING-EDITOR': ['Managing Editor', 'Managing Editor'],
            'EDITORIAL-BOARD-MEMBER': ['Editorial Board Members', 'Editorial Board Members'],
            'HORNORARY-EDITOR': ['Honorary Editor', 'Honorary Editor'],
            'EDITOR': ['Editor', 'Editor'],
            'EDITOR-AT-LARGE': ['Editors-at-Large', 'Editors-at-Large'],
            'FOUNDING-EDITOR': ['Founding Editors', 'Founding Editors'],
            'TECHNICAL-EDITOR': ['Technical-Editor', 'Technical-Editor'],
            'ASSOCIATE-EDITOR': ['Associate Editors', 'Associate Editors'],
            'ADVISORY-BOARD-MEMBER': ['Advisory Board Members', 'Advisory Board Members'],
            'EDITORIAL-ASSISTANT': ['Editorial Assistant', 'Editorial Assistant'],
            'SCIENTIFIC-ADVISORY-BOARD-CHAIR': ['Scientific Advisory Board Chair', 'Scientific Advisory Board Chair'],
            'SCIENTIFIC-ADVISORY-BOARD-MEMBER': ['Scientific Advisory Board Member', 'Scientific Advisory Board Member'],
            'PUBLISHER-REPRESENTATIVE': ['Publisher‘s Representative', 'Publisher‘s Representative'],
            'JOURNAL-SECRETARY': ['Journal Secretary', 'Journal Secretary'],
        },
        scope: ['Aims and Scope', 'Aims and Scope'],
        intr: ['Introduction', 'Introduction'],
        pub: ['Publication', 'Publication'],
        pubs: ['Publishing since', 'Publishing since'],
        eachMonth: ['in', 'in'],
        indexView: ['Indexing / Reviewing', 'Indexing / Reviewing'],
        indexBy: ['Indexed By', 'Indexed By'],
        reviewedBy: ['Reviewed By', 'Reviewed By'],
        citation: ['Citation Report Metrics', 'Citation Report Metrics'],
        sponsorship: ['Sponsorship', 'Sponsorship'],
        specialNotices: ['Special Notices', 'Special Notices'],
        general: ['General Information', 'General Information'],
        about: ['About Submissions', 'About Submissions'],
        selection: ['Selection and Acceptance', 'Selection and Acceptance'],
        acceptedTitle: ['Accepted Papers Currently Awaiting Publication', 'Accepted Papers Currently Awaiting Publication'],
        actIssue: ['For Issue', 'For Issue'],
        actSeq: ['Seq.', 'Seq.'],
        actTitle: ['Title', 'Title'],
        actAuthor: ['Author(s)', 'Author(s)'],
        actDate: ['Date of Acceptance', 'Date of Acceptance'],
        newest: ['NEWEST', 'NEWEST'],
        yearVolume: ['Volume |V| (|Y|)', 'Volume |V| (|Y|)'],
        yearVolume1: ['Volume |Y|', 'Volume |Y|'],
        volume: ['Volume', 'Volume'],
        qq: ['Number |Q|', 'Number |Q|'],
        mailTo: ['E-Mail', 'E-Mail'],
        webLink: ['Link', 'Link'],
        totalI: ['|T| articles in total', '|T| articles in total'],
        totalV: ['|T| articles in volume', '|T| articles in volume'],
        contents: ['Contents', 'Contents'],
        pages: ['pp.', 'pp.'],
        // thisVolumne: ['本卷', 'This Volume'],
        volumnes: ['Volumes', 'Volumes'],
        thisIssue: ['This Issue', 'This Issue'],
        allVolumes: ['All Volumes', 'All Volumes'],
        thisVolume: ['This Volume', 'This Volume'],
        journalHome: ['Home page', 'Home page'],
        VAI: ['Volumes and issues', 'Volumes and issues'],
        authors: ['Authors', 'Authors'],
        author: ['Author', 'Author'],
        authorInfo: ['Author information', 'Author information'],
        contributor: ['Contributor: ', 'Contributor: '],
        contributions: ['Contributions', 'Contributions'],
        contributionsTo: ['Contributions to', 'Contributions to'],
        contributionsAuthor: ['Contributions author', 'Contributions author'],
        authorInfoA: ['Author and Affiliations', 'Author and Affiliations'],
        abstract: ['Abstract', 'Abstract'],
        keyword: ['Keywords', 'Keywords'],
        msc2000: ['2000 Mathematics Subject Classification', '2000 Mathematics Subject Classification'],
        msc2010: ['2010 Mathematics Subject Classification', '2010 Mathematics Subject Classification'],
        msc2020: ['2020 Mathematics Subject Classification', '2020 Mathematics Subject Classification'],
        received: ['Received', 'Received'],
        receivedRevised: ['Received revised', 'Received revised'],
        accepted: ['Accepted', 'Accepted'],
        published: ['Published', 'Published'],
        remark: ['Remark', 'Remark'],
        readOn: ['Read |NAME| on |SITE|', 'Read |NAME| on |SITE|'],
        noContents: ['Content has not yet been published online.', 'Content has not yet been published online.'],
        noVolumes: ['There are no volumes at the moment.', 'There are no volumes at the moment.'],
        closeIssues: ['Closed Issues Awaiting Publication', 'Closed Issues Awaiting Publication'],
        closeIssueRight: ['Currently, there are no closed issues awaiting publication.', 'Currently, there are no closed issues awaiting publication.'],
        acceptedRight: ['Currently, there are no papers to be published.', 'Currently, there are no papers to be published.'],
        noJournal: ['No Journal', 'No Journal']
    },

    month: {
        1: ['January', 'January'],
        2: ['February', 'February'],
        3: ['March', 'March'],
        4: ['April', 'April'],
        5: ['May', 'May'],
        6: ['June', 'June'],
        7: ['July', 'July'],
        8: ['August', 'August'],
        9: ['September', 'September'],
        10: ['October', 'October'],
        11: ['November', 'November'],
        12: ['December', 'December']
    },

    cookie: {
        notice: ['We use cookies to improve your experience with our website. By clicking "Agree and Close", you agree to store and use cookies on your device. Click "more information" to obtain detailed information about the use of cookies on this website.', 'We use cookies to improve your experience with our website. By clicking "Agree and Close", you agree to store and use cookies on your device. Click "more information" to obtain detailed information about the use of cookies on this website.'],
        AC: ['Agree and Close', 'Agree and Close'],
        more: ['More Information', 'More Information'],
        title: ['Privacy Policy', 'Privacy Policy']

    },
    intro: {
        i1: ['International Press of Boston, Inc. is a scholarly publishing company founded in <span class="n-c-UI-blue">1992</span>, and based in Somerville, Massachusetts (near to Harvard University).', 'International Press of Boston, Inc. is a scholarly publishing company founded in <span class="n-c-UI-blue">1992</span>, and based in Somerville, Massachusetts (near to Harvard University).'],
        i2: ['We currently publish <span class="n-c-UI-blue">26 peer-reviewed</span> journals and two annual books dealing with various fields of current research in pure and applied mathematics.', 'We currently publish <span class="n-c-UI-blue">26 peer-reviewed</span> journals and two annual books dealing with various fields of current research in pure and applied mathematics.'],
        i3: ['We also publish high-level mathematics and mathematical physics book titles, including <span class="n-c-UI-blue">monographs, textbooks, and several long-running</span> book series.', 'We also publish high-level mathematics and mathematical physics book titles, including <span class="n-c-UI-blue">monographs, textbooks, and several long-running</span> book series.'],
        i4: ['International Press draws its editors and authors from a worldwide network of talent which includes many of the most notable and important mathematicians in their respective fields.', 'International Press draws its editors and authors from a worldwide network of talent which includes many of the most notable and important mathematicians in their respective fields.'],
    },

    unit: {
        f: ['份', ''],
        b: ['本', ''],
        p: ['pages', 'pages'],
        b1: ['books', 'books'],
        x: ['Series', 'Series']
    },

    book: {
        belong: ['The volume is part of the |SS| book series', 'The volume is part of the |SS| book series'],
        noAuth: ['Sorry, you do not have permission to preview this book', 'Sorry, you do not have permission to preview this book'],
        forthcoming: ['Forthcoming', 'Forthcoming'],
        recently: ['Recently Published', 'Recently Published'],
        featured: ['Featured Publications', 'Featured Publications'],
        subscribed: ['Subscribed', 'Subscribed'],
        MSC2020: ['MSC2020', 'MSC2020'],
        moreAuthor: ['Click to view more |COUNT| authors', 'Click to view more |COUNT| authors'],
        pubDate: ['Pub. Date', 'Pub. Date'],
        medium: ['Medium', 'Medium'],
        binding: ['Binding', 'Binding'],
        size: ['Size, Etc.', 'Size, Etc.'],
        status: ['Status', 'Status'],
        price: ['List Price', 'List Price'],
        noBook: ['No Book', 'No Book'],
        printBookInfo: ['Print Book Information', 'Print Book Information'],
    },

    common: {
        contact: ['Contact', 'Contact'],
        more: ['More', 'More'],
        pages: ['Pages', 'Pages'],
        updateTime: [' This page last updated', ' This page last updated'],
        updateLocalTime: ['Local Time', 'Local Time'],
        parentNotice: ['This volume is sold both independently and as part of a volume set.', 'This volume is sold both independently and as part of a volume set.'],
        childNotice: ['This is a set comprising the following volumes, which may be purchased independently:', 'This is a set comprising the following volumes, which may be purchased independently:'],
        back: ['Back', 'Back'],
        catalogue: ['Catalogue', 'Catalogue'],
        allJournal: ['All Journals Home Page', 'All Journals Home Page'],
        allJournalOnline: ['Links to journal content online', 'Links to journal content online'],
        allOnline: ['All Journals Contents Online', 'All Journals Contents Online'],
        journalRing: ['The journal is receiving subscriptions', 'The journal is receiving subscriptions'],
        STY: ['Books by Shing-Tung Yau', 'Books by Shing-Tung Yau'],
        contactUs: ['Contacts', 'Contacts'],
        bookRing: ['The book is receiving subscriptions', 'The book is receiving subscriptions'],
        byTitle: ['By Title', 'By Title'],
        byDate: ['By Date', 'By Date'],
        byPrice: ['Price', 'Price'],
        total: ['Total', 'Total'],
        publishDate: ['Publish Date', 'Publish Date'],
        publishBy: ['Editors', 'Editors'],
        salePrice: ['List Price', 'List Price'],
        salePrice1: ['Price', 'Price'],
        cBuy: ['Click to Buy', 'Click to Buy'],
        introduce: ['Description', 'Description'],
        view: ['View', 'View'],
        PDFAll: ['Read full text', 'Read full text'],
        PDFTable: ['Table of contents', 'Table of contents'],
        PDFPreview: ['Preview', 'Preview'],
        publications: ['Publications', 'Publications'],
        publisher: ['Publisher', 'Publisher'],
        noAuthBook: ['Unauthorized access to read full text. Please contact us to subscribe (More...).', 'Unauthorized access to read full text. Please contact us to subscribe (More...).'],
        subscribe: ['Subscribe', 'Subscribe']
    },

    nav: {
        bookHome: ['Books Home', 'Books Home'],
        home: ['Home', 'Home'],
        QQ: ['Journals', 'Journals'],
        QQHome: ['Home Page', 'Home Page'],
        QQOnline: ['Contents Online', 'Contents Online'],
        QQOnlineShort: ['Contents Online', 'Contents Online'],
        online: ['Journal Content Online', 'Journal Content Online'],
        book: ['Books', 'Books'],
        bookSuccession: ['Book Series', 'Book Series'],
        bookSuccessionList: ['Book Series List', 'Book Series List'],
        bookLight: ['Textbooks', 'Textbooks'],
        STY: ['Books by S.T. Yau', 'Books by S.T. Yau'],
        ordering: ['Information & Ordering', 'Information & Ordering'],
        contactUs: ['Contacts', 'Contacts'],
        mine: ['User Center', 'User Center'],
        login: ['Login', 'Login'],
        pLogin: ['Personal Login', 'Personal Login'],
        oLogin: ['Group Login', 'Group Login'],
        logout: ['Logout', 'Logout'],
        changeLogin: ['After switching, the current login will be invalidated. Are you sure to switch?', 'After switching, the current login will be invalidated. Are you sure to switch?'],
        joinMail: ['Join Our Mailing Lists', 'Join Our Mailing Lists']
    },

    mine: {
        recent: ['Latest browsing', 'Latest browsing'],
        subscribe: ['My subscription', 'My subscription'],
        message: ['My news', 'My news'],
        open: ['Open Access Journals', 'Open Access Journals'],
        noHis: ['You have no browsing record.', 'You have no browsing record.'],
        noSub: ['You have no subscription record.', 'You have no subscription record.']
    },

    home: {
        sold: ['2025 journal subscriptions now being sold', '2025 journal subscriptions now being sold'],
        soldDesc: ['We are currently selling 2025 journal subscriptions.', 'We are currently selling 2025 journal subscriptions.'],
        soldBtn: ['Please visit <span class="n-c-UI-blue n-f-underline c-hover-target">our Journal Subscriptions page</span> to download the 2025 subscription list price schedule.', 'Please visit <span class="n-c-UI-blue n-f-underline c-hover-target">our Journal Subscriptions page</span> to download the 2025 subscription list price schedule.'],
        sTitle: ['International Press brings you <span class="n-c-base">Acta Mathematica</span> and <span class="n-c-base">Arkiv för Matematik</span>', 'International Press brings you <span class="n-c-base">Acta Mathematica</span> and <span class="n-c-base">Arkiv för Matematik</span>'],
        sContent1: [
            "International Press is the exclusive producer and distributor of the venerable journals Acta Mathematica and Arkiv för Matematik, by arrangement with their owner and publisher, the Institut Mittag-Leffler, of the Royal Swedish Academy of Sciences.</br>",
            "International Press is the exclusive producer and distributor of the venerable journals Acta Mathematica and Arkiv för Matematik, by arrangement with their owner and publisher, the Institut Mittag-Leffler, of the Royal Swedish Academy of Sciences.</br>"
        ],
        sContent2: [
            "Moreover, by arrangement with the Institut Mittag-Leffler, International Press provides <span class='n-c-base'>fully open online access</span> to the entire content of Acta Mathematica and Arkiv för Matematik — from their first issues (of 1882 and 1949 respectively) to the most recent.</br>",
            "Moreover, by arrangement with the Institut Mittag-Leffler, International Press provides <span class='n-c-base'>fully open online access</span> to the entire content of Acta Mathematica and Arkiv för Matematik — from their first issues (of 1882 and 1949 respectively) to the most recent.</br>"
        ],
        sContent3: [
            "For current information about Acta Mathematica and Arkiv för Matematik, please visit the <a class='c-tab-link n-f-underline c-tab-link-event' href='http://www.mittag-leffler.se/about-us' target='_blank'>website of the Institut Mittag-Leffler</a>, and see the journals’ home pages on our website at <a class='c-tab-link n-f-underline c-tab-link-event' href='/acta' rel='nofollow'>link.intlpress.com/acta</a> and <a class='c-tab-link n-f-underline c-tab-link-event' href='/arkiv'>link.intlpress.com/arkiv</a>.",
            "For current information about Acta Mathematica and Arkiv för Matematik, please visit the <a class='c-tab-link n-f-underline c-tab-link-event' href='http://www.mittag-leffler.se/about-us' target='_blank'>website of the Institut Mittag-Leffler</a>, and see the journals’ home pages on our website at <a class='c-tab-link n-f-underline c-tab-link-event' href='/acta' rel='nofollow'>link.intlpress.com/acta</a> and <a class='c-tab-link n-f-underline c-tab-link-event' href='/arkiv'>link.intlpress.com/arkiv</a>."
        ]
    },

    nui: {
        exit: ['Press the return key again to exit the application', 'Press the return key again to exit the application'],
        browser: ['Your browser version is too low. Please use edge or other advanced third-party browser to visit', 'Your browser version is too low. Please use edge or other advanced third-party browser to visit'],
        loading: ['Loading', 'Loading'],

        scroll: {
            pullUpNotice: ['Pull up loading more', 'Pull up loading more'],
            pullUpEndNotice: ['No more', 'No more']
        },

        dateSelect: {
            title: ['Date Selector', 'Date Selector'],
            monthTitle: ['Month Selector', 'Month Selector'],
            year: ['-', '-'],
            month: ['-', '-'],
            monthen: ['-', ''],
            day: ['', ''],
            pleaseSelectRange: ['Please select range', 'Please select range'],
            single: ['Single Day', 'Single Day'],
            multiple: ['Multiple Days', 'Multiple Days'],
            weekTitles: {
                0: ['Sun', 'Sun'],
                1: ['Mon', 'Mon'],
                2: ['Tue', 'Tue'],
                3: ['Web', 'Web'],
                4: ['Thu', 'Thu'],
                5: ['Fri', 'Fri'],
                6: ['Sat', 'Sat']
            }
        },

        page: {
            prev: ['Prev', 'Prev'],
            next: ['Next', 'Next']
        },

        upload: {
            penddingNotice: ['Uploding', 'Uploding'],
            uploadFailed: ['上传失败', '上传失败'],
            fileError: ['File type or format error', 'File type or format error'],
            fileSizeTooSmall: ['The file you uploaded is too small, please upload again', 'The file you uploaded is too small, please upload again'],
            fileSizeTooLarge: ['The file you uploaded is too large, up to |SIZE|, please upload again', 'The file you uploaded is too large, up to |SIZE|, please upload again'],
            all: ['all', 'all'],
            warn: ['Please upload |EXT| suffix and less than |SIZE| files, up to |COUNT|', 'Please upload |EXT| suffix and less than |SIZE| files, up to |COUNT|']
        },

        dateTypeSelect: {
            week: ['Week', 'Week'],
            month: ['Month', 'Month'],
            year: ['Year', 'Year'],
            more: ['More', 'More']
        },

        selectAddress: {
            noLevelData: ['No data', 'No data']
        },

        selectAddress: {
            noLevelData: ['No data', 'No data'],
            province: ['Province', 'Province'],
            city: ['City', 'City'],
            area: ['Area', 'Area']
        },

        common: {
            timeout: ['Trying to load, please wait', 'Trying to load, please wait'],
            timeoutError: ['Request timed out, please adjust the network to try again', 'Request timed out, please adjust the network to try again'],
            selected: ['Selected', 'Selected'],
            noSelected: ['No selected', 'No selected'],
            cancel: ['Cancel', 'Cancel'],
            ok: ['OK', 'OK'],
            pleaseEnterKeyword: ['Please enter keyword', 'Please enter keyword'],
            pleaseEnter: ['Please enter', 'Please enter'],
            pleaseSelect: ['Please select', 'Please select'],
            noData: ['No data', 'No data'],
            emptyData: ['No data', 'No data'],
            complete: ['Complete', 'Complete'],
            save: ['Save', 'Save'],
            submit: ['Submit', 'Submit'],
            add: ['Add', 'Add'],
            delete: ['Delete', 'Delete'],
            edit: ['Edit', 'Edit'],
            yes: ['Yes', 'Yes'],
            no: ['No', 'No'],
            logout: ['Logout', 'Logout']
        }
    }
};

let LF = {
    exportLanguage: function () {
        var languageIndex = this.getLanguage(),
            languageCopy = extend(true, {}, LAN);

        return this.parseLanguage(languageCopy, languageIndex);
    },
    parseLanguageBySequence: function (lanSequence) {
        var languageIndex = this.getLanguage();

        lanSequence = lanSequence.split('|');
        var lanArr = LAN;
        for (var i = 0; i < lanSequence.length; i++) {
            lanArr = lanArr[lanSequence[i]];
        }
        return Array.isArray(lanArr) ? lanArr[languageIndex] : lanArr;
    },
    parseLanguage: function (language, index) {
        for (let i in language) {
            if (language.hasOwnProperty(i)) {
                if (Array.isArray(language[i])) {
                    language[i] = language[i][index];
                } else {
                    this.parseLanguage(language[i], index);
                }
            }
        }

        return language;
    },
    getLanguage: function () {
        var cache = localStorage.getItem(config.PREFIX + 'LANGUAGE');
        return isNaN(parseInt(cache)) ? 0 : cache;
    },
    setLanguage: function (index) {
        localStorage.setItem(config.PREFIX + 'LANGUAGE', index);
    }
};
export default {
    LAN,
    LF,
    language: LF.exportLanguage()
};
