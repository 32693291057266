export default {
    book: '/api/bgcloud-front/bookmain/homepage',
    bookCount: '/api/bgcloud-front/bookmain/homepage-count',
    subscribed: '/api/bgcloud-front/bookmain/org-auth-books',
    subscribedCount: '/api/bgcloud-front/bookmain/org-auth-books-count',
    bookDetail: '/api/bgcloud-front/bookmain/bookdetail',
    bookSuccession: '/api/bgcloud-front/bookmain/bookseries',
    bookSuccessionList: '/api/bgcloud-front/bookmain/homepage',
    STY: '/api/bgcloud-front/bookmain/homepage',
    bookLight: '/api/bgcloud-front/bookmain/homepage',
    orderCategory: '/api/bgcloud-front/baseinfoordering/category',
    orderInfo: '/api/bgcloud-front/baseinfoordering/detail',
    pdfCheckAuth: '/api/bgcloud-front/resource/pdf/checkauth',
    cr: '/api/bgcloud-front/periodical/param/detail?paramKey=system.copyright',
    techweblink: '/api/bgcloud-front/periodical/param/detail?paramKey=system.techweblink',
    tagsByCategory: '/api/bgcloud-front/bookmain/tags-by-type',
    getTagName: '/api/bgcloud-front/bookmain/tag-detail',

    journal: '/api/bgcloud-front/periodical/org-auth-periodical',
    journalList: '/api/bgcloud-front/periodical/detail',
    journalAnnualPanel: '/api/bgcloud-front/periodical/volumes-by-annual-issue',
    journalPanel: '/api/bgcloud-front/periodical/issue-content-list',

    journalAuthorPanel: '/api/bgcloud-front/periodical/issue-content-list-by-author',

    journalFirst: '/api/bgcloud-front/periodical/online-first-articles-list',
    journalDetail: '/api/bgcloud-front/periodical/issue-content-detail',
    homeData: '/api/bgcloud-front/bookmain/homedata',
    periodicalByAbbr: '/api/bgcloud-front/periodical/get-periodical-by-abbr',
    checkContentPermission: "/api/bgcloud-front/resource/pdf/check-content-permission",
    organiztionLogin: "/api/bgcloud-auth/oauth/token",

    userOpenAcc: "/api/bgcloud-front/periodical/select-openaccess-volumes?size=10",
    journalHistory: '/api/bgcloud-front/periodical/access-history-periodical-content?size=100',
    bookHistory: '/api/bgcloud-front/bookmain/access-history-books?size=100',
    jSub: '/api/bgcloud-front/periodical/org-auth-volumes?size=99999999',
    bSub: '/api/bgcloud-front/bookmain/org-auth-books?size=9999999',

    getJDetailIds: '/api/bgcloud-front/periodical/issue-content-pmvalue-by-id',

    nui: {
        common: {
            upload: 'api/Attachment/Upload',
            address: 'api/AreaSelect/getlist/'
        }
    }
};
